<template>
  <main class="flex-1 overflow-x-hidden overflow-y-auto px-2">
    <div class="container mx-auto">
      <h1
        class="flex items-center font-sans font-bold break-normal text-indigo-500  text-xl md:text-2xl py-2"
      >
        Operators List
      </h1>

      <div class="mb-4">
        <!-- component -->
        <div id="" class="">
          <table id="" class="border-collapse w-full text-sm">
            <thead>
              <tr>
                <th
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
                >
                  Name
                </th>
                <th
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
                >
                  Username
                </th>
                <th
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
                >
                  Role
                </th>
                <th
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
                >
                  Is Active
                </th>
                <th
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-if="!items"
                class="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0"
              >
                <td
                  class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static"
                  colspan="5"
                >
                  Please wait...
                </td>
              </tr>
              <tr
                v-for="(item, i) in items"
                :key="i"
                class="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0"
              >
                <td
                  class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static"
                >
                  {{ item.name }}
                </td>
                <td
                  class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static"
                >
                  {{ item.username }}
                </td>
                <td
                  class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static"
                >
                  {{ role(item.role) }}
                </td>
                <td
                  class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static"
                >
                  {{ item.status === 1 ? "Yes" : "No" }}
                </td>
                <td
                  class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static"
                >
                  <div v-if="loading === i">
                    Updating...
                  </div>
                  <div v-if="loading != i">
                    <form
                      method="post"
                      @submit.prevent="updateStatus(i, item.id, item.status)"
                    >
                      <button
                        class="focus:outline-none text-blue-600 text-sm hover:underline"
                        type=""
                      >
                        {{ item.status === 1 ? "Deactive" : "Active" }}
                      </button>
                    </form>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "Operators",
  data() {
    return {
      items: [],
      loading: -1
    };
  },
  created() {
    this.get();
  },
  methods: {
    async get() {
      try {
        const res = await this.$axios.get(`${this.$api}/operator/get`);
        this.items = res.data.data;
      } catch (e) {
        console.log(e);
      }
    },
    role(rl) {
      if (rl === "L1") return "Super Admin";
      if (rl === "L2") return "Admin";
      if (rl === "L3") return "Employee";
    },
    async updateStatus(i, id, status) {
      try {
        this.loading = i;
        const res = await this.$axios.put(
          `${this.$api}/operator/update-status`,
          {
            id,
            status
          }
        );
        if (res.data.success) {
          let index = this.items.findIndex(v => v.id == id);
          if (index > -1) {
            this.items[index].status = status == 1 ? 0 : 1;
          }
        }
        this.loading = -1;
      } catch (e) {
        this.loading = -1;
        console.log(e);
      }
    }
  }
};
</script>

<style>
/*table {
  width: 100%;
}
table td {
  overflow-wrap: anywhere;
}*/
</style>
